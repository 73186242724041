* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-image: url(../public/images/bg.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
}

.cards {
  background-color: rgb(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}

.card-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  padding: 5px;
  border-radius: 100%;
  background-color: #fff;
  overflow: hidden;

}

.card-img img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.cards button {
  background-color: #fff;
  width: 100%;
  outline: none;
  border: none;
  padding: 10px;
  font-weight: 700;
  font-size: 20px;
  border-radius: 5px;
}

.cards button:hover {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}



/* ============= */

.Results h1{
  text-align: center;

}

.cards h2{
  font-size: 50px;
  font-weight: 700;
}